import HomeTwo from "../components/home/home-2/hometwo";
import LayoutTwo from "../layout/layout-2";
import Wrapper from "../layout/wrapper";
import React from "react";


const index = () => {
  document.title="Home 2| StudyNZ";
  return (
    <Wrapper>
      <LayoutTwo>
        <HomeTwo />
      </LayoutTwo>
    </Wrapper>
  );
};

export default index;
