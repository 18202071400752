import React from "react";
import Layout from "../layout/layout";
import HomeOne from "../components/home/home/home";
import Wrapper from "../layout/wrapper";

const index = () => {
  document.title="Study and Migration Advice | StudyNZ";
  return (
    <Wrapper>
      <Layout>
        <HomeOne />
      </Layout>
    </Wrapper>
  );
};

export default index;
