import Blog from "../components/blog";
import Wrapper from "../layout/wrapper";
import React from "react";

const index = () => {
  document.title="Blog | StudyNZ";
  return (
    <Wrapper>
      <Blog />
    </Wrapper>
  );
};

export default index;
