const research_data = [
  {
    id: 1,
    color: "",
    img: "/assets/img/research/research-thumb-01.jpg",
    category: "Oncology",
    title: "Catalysis Processes",
    des: "Nam eget dui vel quam sodales semper quis porttitor tortor. Vivamus quis ex nulla.Nam eget dui vel quam",
  },
  {
    id: 2,
    color: "tp-pink",
    img: "/assets/img/research/research-thumb-02.jpg",
    category: "Pathology",
    title: "Organic Synthesis",
    des: "Nam eget dui vel quam sodales semper quis porttitor tortor. Vivamus quis ex nulla.Nam eget dui vel quam",
  },
  {
    id: 3,
    color: "tp-green",
    img: "/assets/img/research/research-thumb-03.jpg",
    category: "Biosafety, Incubator",
    title: "Hemoglobin Test",
    des: "Nam eget dui vel quam sodales semper quis porttitor tortor. Vivamus quis ex nulla.Nam eget dui vel quam",
  },
  {
    id: 4,
    color: "tp-sky",
    img: "/assets/img/research/research-thumb-04.jpg",
    category: "Hemoglobin",
    title: "Surface Cleaning",
    des: "Nam eget dui vel quam sodales semper quis porttitor tortor. Vivamus quis ex nulla.Nam eget dui vel quam",
  },
  {
    id: 5,
    color: "tp-green",
    img: "/assets/img/research/research-thumb-05.jpg",
    category: "Oncology",
    title: "Hemoglobin Test",
    des: "Nam eget dui vel quam sodales semper quis porttitor tortor. Vivamus quis ex nulla.Nam eget dui vel quam",
  },

  {
    id: 6,
    color: "",
    img: "/assets/img/research/research-thumb-06.jpg",
    category: "Incubator",
    title: "Polymer Science",
    des: "Nam eget dui vel quam sodales semper quis porttitor tortor. Vivamus quis ex nulla.Nam eget dui vel quam",
  },
  {
    id: 7,
    color: "tp-pink",
    img: "/assets/img/research/research-thumb-07.jpg",
    category: "Hemoglobin",
    title: "Cancer Cell Biology",
    des: "Nam eget dui vel quam sodales semper quis porttitor tortor. Vivamus quis ex nulla.Nam eget dui vel quam",
  },
  {
    id: 8,
    color: "tp-sky",
    img: "/assets/img/research/research-thumb-08.jpg",
    category: "Hemoglobin",
    title: "DNA Vaccines",
    des: "Nam eget dui vel quam sodales semper quis porttitor tortor. Vivamus quis ex nulla.Nam eget dui vel quam",
  },
  {
    id: 9,
    color: "ttp-green",
    img: "/assets/img/research/research-thumb-09.jpg",
    category: "Hemoglobin",
    title: "Hemoglobin Test",
    des: "Nam eget dui vel quam sodales semper quis porttitor tortor. Vivamus quis ex nulla.Nam eget dui vel quam",
  },
];
export default research_data;
