const team_home_two = [
  {
    id: 1,
    img: "/assets/img/team/team-thumb-05.png",
    name: "Cameron Williamson",
    title: "Genetic Specialist",
    social_links: [
      {
        link: "http://facebook.com",
        target: "_blank",
        icon: "fab fa-facebook-f",
        name: "Facebook",
      },
      {
        link: "http://twitter.com",
        target: "_blank",
        icon: "fab fa-twitter",
        name: "Twitter",
      },

      {
        link: "https://www.instagram.com/",
        target: "_blank",
        icon: "fa-brands fa-instagram",
        name: "instagram",
      },

      {
        link: "https://www.basketball.com/",
        target: "_blank",
        icon: "fa-light fa-basketball",
        name: "basketball",
      },
    ],
  },
  {
    id: 2,
    img: "/assets/img/team/team-thumb-06.png",
    name: "Savannah Nguyen",
    title: "Microbiology Expart",
    social_links: [
      {
        link: "http://facebook.com",
        target: "_blank",
        icon: "fab fa-facebook-f",
        name: "Facebook",
      },
      {
        link: "http://twitter.com",
        target: "_blank",
        icon: "fab fa-twitter",
        name: "Twitter",
      },

      {
        link: "https://www.instagram.com/",
        target: "_blank",
        icon: "fa-brands fa-instagram",
        name: "instagram",
      },

      {
        link: "https://www.basketball.com/",
        target: "_blank",
        icon: "fa-light fa-basketball",
        name: "basketball",
      },
    ],
  },
  {
    id: 3,
    img: "/assets/img/team/team-thumb-07.png",
    name: "Darlene Robertson",
    title: "Genetic Specialist",
    social_links: [
      {
        link: "http://facebook.com",
        target: "_blank",
        icon: "fab fa-facebook-f",
        name: "Facebook",
      },
      {
        link: "http://twitter.com",
        target: "_blank",
        icon: "fab fa-twitter",
        name: "Twitter",
      },

      {
        link: "https://www.instagram.com/",
        target: "_blank",
        icon: "fa-brands fa-instagram",
        name: "instagram",
      },

      {
        link: "https://www.basketball.com/",
        target: "_blank",
        icon: "fa-light fa-basketball",
        name: "basketball",
      },
    ],
  },
  {
    id: 4,
    img: "/assets/img/team/team-thumb-08.png",
    name: "Marvin McKinney",
    title: "MEDICAL DOCTOR",
    social_links: [
      {
        link: "http://facebook.com",
        target: "_blank",
        icon: "fab fa-facebook-f",
        name: "Facebook",
      },
      {
        link: "http://twitter.com",
        target: "_blank",
        icon: "fab fa-twitter",
        name: "Twitter",
      },

      {
        link: "https://www.instagram.com/",
        target: "_blank",
        icon: "fa-brands fa-instagram",
        name: "instagram",
      },

      {
        link: "https://www.basketball.com/",
        target: "_blank",
        icon: "fa-light fa-basketball",
        name: "basketball",
      },
    ],
  },
];

export default team_home_two;
