const shop_data = [
  {
    id: 1,
    img: "/assets/img/shop/shop-01.jpg",
    title: "Glucose Monitoring",
    price: 46.0,
    product_news: "",
    ratting: [
      "fa-solid fa-star",
      "fa-solid fa-star",
      "fa-solid fa-star",
      "fa-solid fa-star",
      "fa-regular fa-star",
    ],
  },
  {
    id: 2,
    img: "/assets/img/shop/shop-02.jpg",
    title: "Single Hand Gloves",
    price: 58.1,
    product_news: "",
    ratting: [
      "fa-solid fa-star",
      "fa-solid fa-star",
      "fa-solid fa-star",
      "fa-solid fa-star",
      "fa-regular fa-star",
    ],
  },
  {
    id: 3,
    img: "/assets/img/shop/shop-03.jpg",
    title: "Pharmaceuticals Plant",
    price: 88.0,
    product_news: "HOT",
    ratting: [
      "fa-regular fa-star",
      "fa-regular fa-star",
      "fa-regular fa-star",
      "fa-regular fa-star",
      "fa-regular fa-star",
    ],
  },
  {
    id: 4,
    img: "/assets/img/shop/shop-04.jpg",
    title: "Digital Thermometer",
    price: 70.0,
    product_news: "NEW",
    ratting: [
      "fa-solid fa-star",
      "fa-solid fa-star",
      "fa-solid fa-star",
      "fa-solid fa-star",
      "fa-regular fa-star",
    ],
  },
  {
    id: 5,
    img: "/assets/img/shop/shop-05.jpg",
    title: "Stethoscope Superb",
    price: 96.0,
    product_news: "",
    ratting: [
      "fa-solid fa-star",
      "fa-solid fa-star",
      "fa-solid fa-star",
      "fa-solid fa-star",
      "fa-regular fa-star",
    ],
  },
  {
    id: 6,
    img: "/assets/img/shop/shop-06.jpg",
    title: "Sphygmomanometer ECO",
    price: 69.0,
    product_news: "",
    ratting: [
      "fa-solid fa-star",
      "fa-solid fa-star",
      "fa-solid fa-star",
      "fa-solid fa-star",
      "fa-regular fa-star",
    ],
  },
  {
    id: 7,
    img: "/assets/img/shop/shop-07.jpg",
    title: "Sphygmomanometer ECO",
    price: 70.0,
    product_news: "",
    ratting: [
      "fa-regular fa-star",
      "fa-regular fa-star",
      "fa-regular fa-star",
      "fa-regular fa-star",
      "fa-regular fa-star",
    ],
  },
  {
    id: 8,
    img: "/assets/img/shop/shop-08.jpg",
    title: "Hand Gloves",
    price: 52.0,
    product_news: "",
    ratting: [
      "fa-solid fa-star",
      "fa-solid fa-star",
      "fa-solid fa-star",
      "fa-solid fa-star",
      "fa-regular fa-star",
    ],
  },
  {
    id: 9,
    img: "/assets/img/shop/shop-09.jpg",
    title: "Sphygmomanometer",
    price: 66.0,
    product_news: "",
    ratting: [
      "fa-solid fa-star",
      "fa-solid fa-star",
      "fa-solid fa-star",
      "fa-solid fa-star",
      "fa-regular fa-star",
    ],
  },
  {
    id: 10,
    img: "/assets/img/shop/shop-10.jpg",
    title: "Glucometer",
    price: 85.0,
    product_news: "",
    ratting: [
      "fa-solid fa-star",
      "fa-solid fa-star",
      "fa-solid fa-star",
      "fa-solid fa-star",
      "fa-regular fa-star",
    ],
  },
  {
    id: 11,
    img: "/assets/img/shop/shop-11.jpg",
    title: "Oxygen Breathing Machine",
    price: 70.0,
    product_news: "",
    ratting: [
      "fa-regular fa-star",
      "fa-regular fa-star",
      "fa-regular fa-star",
      "fa-regular fa-star",
      "fa-regular fa-star",
    ],
  },
  {
    id: 12,
    img: "/assets/img/shop/shop-12.jpg",
    title: "Surgical Latex Gloves",
    price: 90.0,
    product_news: "",
    ratting: [
      "fa-solid fa-star",
      "fa-solid fa-star",
      "fa-solid fa-star",
      "fa-solid fa-star",
      "fa-regular fa-star",
    ],
  },
];
export default shop_data;
