
import {BrowserRouter, Routes, Route } from "react-router-dom";


import ErrorPage from "./pages/404";
import AboutPage from "./pages/about";
import BlogPage from "./pages/blog";
import CartPage from "./pages/cart";
import ContactPage from "./pages/contact";
import CheckoutPage from "./pages/checkout";
import FAQ from "./pages/faq";
import HomeOne from "./pages/index";
import HomeTwo from "./pages/home-2";
import HomeThree from "./pages/home-3";
import PricingPlan from "./pages/pricing-plan";
import ProductDetails from "./pages/product-details";
import ProjectDetails from "./pages/project-details";
import Research from "./pages/research";
import Service from "./pages/service";
import ServiceTwo from "./pages/service-2";
import ServiceThree from "./pages/service-3";
import ServiceDetails from "./pages/service-details";
import ShopPage from "./pages/shop";
import TeamOne from "./pages/team-1";
import TeamTwo from "./pages/team-2";
import TeamDeatails from "./pages/team-details";
import CourseDetails from "./pages/service-details";
// import SignupPage from "./pages/signup";
// import LoginPage from "./pages/login";



function App() {
	return (
		<BrowserRouter>
			{/* <ScrollToTop /> */}
			<Routes>
				<Route path="/" element={<HomeOne />} />
				<Route path="home-2" element={<HomeTwo />} />
				<Route path="home-3" element={<HomeThree />} />
				<Route path="checkout" element={<CheckoutPage />} />
				<Route path="product-details" element={<ProductDetails />} />
				<Route path="project-details" element={<ProjectDetails />} />
				<Route path="blog" element={<BlogPage />} />
				<Route path="service" element={<Service />} />
				<Route path="service-2" element={<ServiceTwo />} />
				<Route path="service-3" element={<ServiceThree />} />
				<Route path="service-details" element={<ServiceDetails />} />
				<Route path="course-details/:id" element={<CourseDetails />} />
				<Route path="about" element={<AboutPage />} />
				<Route path="team-1" element={<TeamOne />} />
				<Route path="team-2" element={<TeamTwo />} />
				<Route path="team-details" element={<TeamDeatails />} />
				<Route path="shop" element={<ShopPage />} />
				<Route path="cart-page" element={<CartPage />} />
				<Route path="research" element={<Research />} />
				<Route path="pricing-plan" element={<PricingPlan />} />
				<Route path="contact" element={<ContactPage />} />
				<Route path="faq" element={<FAQ />} />
				<Route path="*" element={<ErrorPage />} />
			</Routes>
			
		</BrowserRouter>
	);
}

export default App;
