import ServiceOne from "../components/services/service/service-one";
import Wrapper from "../layout/wrapper";
import React from "react";

const index = () => {
  document.title="Services | StudyNZ";
  return (
    <Wrapper>
      <ServiceOne />
    </Wrapper>
  );
};

export default index;
