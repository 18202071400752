import ContactUs from "../components/contact/contact";
import Wrapper from "../layout/wrapper";
import React from "react";

const index = () => {
  document.title="Contact Us | StudyNZ";
  return (
    <Wrapper>
      <ContactUs />
    </Wrapper>
  );
};

export default index;
