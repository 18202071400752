import Checkout from '../components/checkout';
import Wrapper from '../layout/wrapper';
import React from 'react';

const index = () => {
    document.title="Checkout | StudyNZ";
    return (
      <Wrapper>
            <Checkout />
      </Wrapper>
    );
};

export default index;