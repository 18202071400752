import React, { useState } from "react";
import {Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import expolreNow from "../../../data/explore-now";


const ExploreNow = () => {
    const [NFTList, setNFTList] = useState(expolreNow);

    const category = (e) => {
        setNFTList(
            expolreNow.filter((item) => item.category === e));
    };

    const programLevel = (e) => {
        setNFTList(
            expolreNow.filter((item) => item.programLevel === e));
    };

    const location = (e) => {
        setNFTList(
            expolreNow.filter((item) => item.location === e));
    };

    const searchNFT = () => {
        var searchProductList = document.getElementById("searchProductList");
        var inputVal = searchProductList.value.toLowerCase();
        function filterItems(arr, query) {
            return arr.filter(function (el) {
                return el.courseTitle.toLowerCase().indexOf(query.toLowerCase()) !== -1;
            });
        }
        var filterData = filterItems(expolreNow, inputVal);
        if (filterData.length === 0) {
            document.getElementById("noresult").style.display = "block";
            document.getElementById("loadmore").style.display = "none";
        } else {
            document.getElementById("noresult").style.display = "none";
            document.getElementById("loadmore").style.display = "block";
        }
        setNFTList(filterData);
    };

    const resetFilters = () => {
        setNFTList(expolreNow); // Reset NFTList to the original list
        // Reset any other states or UI elements if needed
        document.getElementById("searchProductList").value = ""; // Clear search field
        document.getElementById("select-category").value = ""; // Reset category select
        document.getElementById("file-type").value = ""; // Reset program level select
        document.getElementById("location-type").value = ""; // Reset location select
        document.getElementById("delivery-type").value = ""; // Reset delivery method select
        document.getElementById("noresult").style.display = "none"; // Hide no result message if it's displayed
        document.getElementById("loadmore").style.display = "block"; // Show load more button
    };
    

    return (
        <>
            <section
                className="services-area pt-95 pb-90 grey-bg mt-60 fix"
                style={{ backgroundImage: `url(/assets/img/shape/shape-bg-01.png)` }}
            >
                <div className="container mx-n60">
                    <Row>
                        <Col lg={12}>
                            <div className="d-flex justify-content-between align-items-middle">
                                <h5 className="mb-2 flex-grow-1">
                                    Explore Courses
                                </h5>
                                <button className="filter-btn-second mb-2" onClick={resetFilters}>
                                    Reset Filters
                                </button>
                            </div>
                            <Card>
                                <CardHeader className="border-0 bg-transparent">
                                    <Row className="row-cols-xxl-4 row-cols-lg-3 row-cols-md-2 row-cols-1 p-4 g-3">
                                        <Col xxl={4} lg={4} md={6} sm={12}>
                                            {/* <p className="fs-10 fw-bold mb-2">Search</p> */}
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search product name"
                                                autoComplete="off"
                                                id="searchProductList"
                                                onKeyUp={searchNFT}
                                            />
                                        </Col>
                                        <Col xxl={2} lg={4} md={6} sm={12}>
                                            {/* <p className="fs-10 fw-bold mb-2">
                                                Category
                                            </p> */}
                                            <select
                                                className="form-control"
                                                data-choices
                                                name="select-category"
                                                data-choices-search-false
                                                id="select-category"
                                                onChange={(e) => category(e.target.value)}
                                            >
                                                <option value="">Select Category</option>
                                                <option value="Business">Business</option>
                                                <option value="Health Sciences">Health Sciences</option>
                                                <option value="Engineering">Engineering</option>
                                                <option value="Collectibles">Collectibles</option>
                                                <option value="Crypto Card">Crypto Card</option>
                                                <option value="Games">Games</option>
                                                <option value="Music">Music</option>
                                                <option value=".....">.....</option>
                                            </select>
                                        </Col>
                                        <Col xxl={2} lg={4} md={6} sm={12}>
                                            {/* <p className="fs-10 fw-bold mb-2">Program Level</p> */}
                                            <select
                                                className="form-control"
                                                data-choices
                                                name="file-type"
                                                data-choices-search-false
                                                id="file-type"
                                                onChange={(e) => programLevel(e.target.value)}
                                            >
                                                <option value="">Select Program Level</option>
                                                <option value="Bachelor's Degree">Bachelor's Degree</option>
                                                <option value="Master's Degree">Master's Degree</option>
                                                <option value="Doctorate / PhD">Doctorate / PhD</option>
                                                <option value="Language Course">Language Course</option>
                                                <option value="Summer Short Course">Summer Short Course</option>
                                                <option value="MBA">MBA</option>
                                            </select>
                                        </Col>
                                        <Col xxl={2} lg={4} md={6} sm={12}>
                                            {/* <p className="fs-10 fw-bold mb-2">
                                                Location
                                            </p> */}
                                            <select
                                                className="form-control"
                                                data-choices
                                                name="location-type"
                                                data-choices-search-false
                                                id="location-type"
                                                onChange={(e) => location(e.target.value)}
                                            >
                                                <option value="">Select Location</option>
                                                <option value="Auckland">Auckland</option>
                                                <option value="Dunedin">Dunedin</option>
                                                <option value="Wellington">Wellington</option>
                                                <option value="Christchurch">Christchurch</option>
                                            </select>
                                        </Col>
                                        <Col xxl={2} lg={4} md={6} sm={12}>
                                            {/* <p className="fs-10 fw-bold mb-2">
                                                Delivery Method
                                            </p> */}
                                            <select
                                                className="form-control"
                                                data-choices
                                                name="delivery-type"
                                                data-choices-search-false
                                                id="delivery-type"
                                                onChange={(e) => location(e.target.value)}
                                            >
                                                <option value="">Select Delivery Method</option>
                                                <option value="On Campus">On Campus</option>
                                                <option value="Online">Online</option>
                                            </select>
                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                    <Row
                        className="row-cols-xxl-1 row-cols-xl-1 row-cols-lg-1 row-cols-md-1 row-cols-1"
                        id="explorecard-list"
                    >

                        {NFTList.map((item, key) => (<Col className="list-element" key={key}>
                            <Card className="explore-box card-animate my-3">
                                <div className="card-content-wrapper d-flex filter__item blue-border wow fadeInUp"
                                        data-wow-delay=".2s">
                                    <div className="explore-place-bid-img flex-grow-1">
                                        <input type="hidden" className="form-control" id="1" />
                                        <div className="d-none">undefined</div>
                                        <img
                                            src={item.img}
                                            alt=""
                                            className="card-img-top explore-img p-3"
                                        />
                                        <div className="bg-overlay"></div>
                                        <div className="place-bid-btn">

                                            <Link to={`/course-details/${item.id}`}  className="btn btn-success">
                                                View
                                                Details
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="card-info flex-grow-1">
                                        <CardBody>
                                            <p className="fw-medium mb-0 float-end">
                                                <i className="mdi mdi-heart text-danger align-middle"></i>{" "}
                                                {item.likes}k
                                            </p>
                                            <h5 className="mb-1">
                                                <Link to="/apps-nft-item-details">{item.courseTitle}</Link>
                                            </h5>
                                            <p className="text-muted mb-0 ms-1">{item.category}</p>
                                        </CardBody>
                                        <div className="card-footer border-top border-top-dashed">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-grow-1 fs-14">

                                                    <i className="ri-price-tag-3-fill text-warning align-bottom me-1"></i>
                                                    Highest: <span className="fw-medium">{item.highestBid}</span>
                                                </div>
                                                <h5 className="flex-shrink-0 fs-14 text-primary mb-0">
                                                    {item.price} students
                                                </h5>
                                            </div>
                                        </div>
                                        <div>
                                            <p className="text-muted mb-0 fs-12 m-2 p-3">{item.courseDes}</p>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>))}
                    </Row>
                    <div
                        className="py-4 text-center"
                        id="noresult"
                        style={{ display: "none" }}
                    >
                        <lord-icon
                            src="https://cdn.lordicon.com/msoeawqm.json"
                            trigger="loop"
                            colors="primary:#8c68cd,secondary:#4788ff"
                            style={{ width: "72px", height: "72px" }}
                        ></lord-icon>
                        <h5 className="mt-4">Sorry! No Result Found</h5>
                    </div>
                    {/* <div className="text-center mb-3">
                        <button className="btn btn-link text-primary mt-2" id="loadmore">
                            <i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>
                            Load More
                        </button>
                    </div> */}
                </div>
            </section>
        </>
    );
};

export default ExploreNow;
