import TeamDetails from "../components/team/team-details/team-details";
import Wrapper from "../layout/wrapper";
import React from "react";

const index = () => {
  document.title="Team Details | StudyNZ";
  return (
    <Wrapper>
      <TeamDetails />
    </Wrapper>
  );
};

export default index;
