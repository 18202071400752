import ProjectDetails from '../components/project-details';
import Wrapper from '../layout/wrapper';
import React from 'react';

const index = () => {
  document.title="Project Details | StudyNZ";
    return (
      <Wrapper>
            <ProjectDetails />
      </Wrapper>
    );
};

export default index;