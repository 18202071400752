import Shop from "../components/shop";
import Wrapper from "../layout/wrapper";
import React from "react";

const index = () => {
  document.title="Shope | StudyNZ";
  return (
    <Wrapper>
      <Shop />
    </Wrapper>
  );
};

export default index;
