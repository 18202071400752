import React from "react";

const Banner = () => {
  return (
    <>
      <section
        className="breadcrumb__area pt-100 pb-120 breadcrumb__overlay"
        style={{
          backgroundImage: `url("/assets/img/banner/breadcrumb-01.jpg")`,
        }}
      >
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-12 col-md-10 col-12">
              <div className="tp-breadcrumb__link d-flex align-items-center">
                <span>
                  StudyNZ : Contact Us
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
