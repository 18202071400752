import FAQ from '../components/faq/faq';
import Wrapper from '../layout/wrapper';
import React from 'react';

const index = () => {
    document.title="FAQ | StudyNZ";
    return (
        <Wrapper>
            <FAQ />
        </Wrapper>
    );
};

export default index;