
const expolreNow = [
    {
        id: 1,
        img: "assets/img/nft/img-3.jpg",
        likes: "37.41",
        programName: "Pre-Foundation Programme",
        courseDes: "If you do not meet the English language requirements to begin the Auckland Foundation Year, you can enrol on our English Language Preparation (ELP) Programme first. The English Language Preparation Programme focuses on four core English language skills: Speaking, Writing, Reading, Listening.",
        category: "Artwork",
        highestBid: "10.35",
        price: "461",
        isActive: false,
        fileType: "gif",
        sales: "On Auction"
    },
    {
        id: 2,
        img: "assets/img/nft/img-03.jpg",
        likes: "19.29",
        programName: "Business English",
        courseDes: "If you do not meet the English language requirements to begin the Auckland Foundation Year, you can enrol on our English Language Preparation (ELP) Programme first. The English Language Preparation Programme focuses on four core English language skills: Speaking, Writing, Reading, Listening.",
        category: "Photography",
        highestBid: "75.3",
        price: "167",
        isActive: true,
        fileType: "jpg",
        sales: "Has Offers"
    },
    {
        id: 3,
        img: "assets/img/nft/img-02.jpg",
        likes: "23.63",
        programName: "English Language Preparation (ELP)",
        courseDes: "If you do not meet the English language requirements to begin the Auckland Foundation Year, you can enrol on our English Language Preparation (ELP) Programme first. The English Language Preparation Programme focuses on four core English language skills: Speaking, Writing, Reading, Listening.",
        category: "Music",
        highestBid: "412.30",
        price: "394",
        isActive: true,
        fileType: "jpg",
        sales: "On Auction"
    },
    {
        id: 4,
        img: "assets/img/nft/gif/img-4.gif",
        likes: "15.93",
        programName: "Evolved Reality",
        courseDes: "If you do not meet the English language requirements to begin the Auckland Foundation Year, you can enrol on our English Language Preparation (ELP) Programme first. The English Language Preparation Programme focuses on four core English language skills: Speaking, Writing, Reading, Listening.",
        category: "Video",
        highestBid: "2.75",
        price: "167",
        isActive: false,
        fileType: "gif",
        sales: "Has Offers"
    },
    {
        id: 5,
        img: "assets/img/nft/img-01.jpg",
        likes: "14.85",
        programName: "Abstract Face Painting",
        courseDes: "If you do not meet the English language requirements to begin the Auckland Foundation Year, you can enrol on our English Language Preparation (ELP) Programme first. The English Language Preparation Programme focuses on four core English language skills: Speaking, Writing, Reading, Listening.",
        category: "Collectibles",
        highestBid: "122.34",
        price: "97",
        isActive: true,
        fileType: "jpg",
        sales: "On Auction"
    },
    {
        id: 6,
        img: "assets/img/nft/img-05.jpg",
        likes: "64.10",
        programName: "Long-tailed Macaque",
        courseDes: "If you do not meet the English language requirements to begin the Auckland Foundation Year, you can enrol on our English Language Preparation (ELP) Programme first. The English Language Preparation Programme focuses on four core English language skills: Speaking, Writing, Reading, Listening.",
        category: "Artwork",
        highestBid: "874.01",
        price: "140",
        isActive: false,
        fileType: "jpg",
        sales: "Has Offers"
    },
    {
        id: 7,
        img: "assets/img/nft/img-06.jpg",
        likes: "36.42",
        programName: "Robotic Body Art",
        courseDes: "If you do not meet the English language requirements to begin the Auckland Foundation Year, you can enrol on our English Language Preparation (ELP) Programme first. The English Language Preparation Programme focuses on four core English language skills: Speaking, Writing, Reading, Listening.",
        category: "Artwork",
        highestBid: "41.65",
        price: "348",
        isActive: true,
        fileType: "jpg",
        sales: "On Auction"
    },
    {
        id: 8,
        img: "assets/img/nft/gif/img-2.gif",
        likes: "94.1",
        programName: "Trendy Fashion Portraits",
        courseDes: "If you do not meet the English language requirements to begin the Auckland Foundation Year, you can enrol on our English Language Preparation (ELP) Programme first. The English Language Preparation Programme focuses on four core English language skills: Speaking, Writing, Reading, Listening.",
        category: "Business",
        highestBid: "674.92",
        price: "563",
        isActive: true,
        fileType: "gif",
        sales: "On Auction"
    },
    {
        id: 9,
        img: "assets/img/nft/img-04.jpg",
        likes: "34.12",
        programName: "Smillevers Crypto",
        courseDes: "If you do not meet the English language requirements to begin the Auckland Foundation Year, you can enrol on our English Language Preparation (ELP) Programme first. The English Language Preparation Programme focuses on four core English language skills: Speaking, Writing, Reading, Listening.",
        category: "Artwork",
        highestBid: "41.658",
        price: "381",
        isActive: true,
        fileType: "jpg",
        sales: "Has Offers"
    },
    {
        id: 10,
        img: "assets/img/nft/gif/img-1.gif",
        likes: "8.42",
        programName: "Patterns Arts & Culture",
        courseDes: "If you do not meet the English language requirements to begin the Auckland Foundation Year, you can enrol on our English Language Preparation (ELP) Programme first. The English Language Preparation Programme focuses on four core English language skills: Speaking, Writing, Reading, Listening.",
        category: "Artwork",
        highestBid: "9.64",
        price: "141",
        isActive: false,
        fileType: "gif",
        sales: "Has Offers"
    },
    {
        id: 11,
        img: "assets/img/nft/img-03.jpg",
        likes: "19.29",
        programName: "Creative Filtered Portrait",
        courseDes: "If you do not meet the English language requirements to begin the Auckland Foundation Year, you can enrol on our English Language Preparation (ELP) Programme first. The English Language Preparation Programme focuses on four core English language skills: Speaking, Writing, Reading, Listening.",
        category: "Video",
        highestBid: "75.3",
        price: "267",
        isActive: true,
        fileType: "mp4",
        sales: "Has Offers"
    },
    {
        id: 12,
        img: "assets/img/nft/gif/img-4.gif",
        likes: "15.93",
        programName: "Evolved Reality",
        courseDes: "If you do not meet the English language requirements to begin the Auckland Foundation Year, you can enrol on our English Language Preparation (ELP) Programme first. The English Language Preparation Programme focuses on four core English language skills: Speaking, Writing, Reading, Listening.",
        category: "Artwork",
        highestBid: "2.75",
        price: "242",
        isActive: true,
        fileType: "gif",
        sales: "On Auction"
    },
    {
        id: 13,
        img: "assets/img/nft/img-01.jpg",
        likes: "14.85",
        programName: "Abstract Face Painting",
        courseDes: "If you do not meet the English language requirements to begin the Auckland Foundation Year, you can enrol on our English Language Preparation (ELP) Programme first. The English Language Preparation Programme focuses on four core English language skills: Speaking, Writing, Reading, Listening.",
        category: "Photography",
        highestBid: "122.34",
        price: "245",
        isActive: false,
        fileType: "jpg",
        sales: "On Auction"
    },
    {
        id: 14,
        img: "assets/img/nft/img-05.jpg",
        likes: "64.10",
        programName: "Long-tailed Macaque",
        courseDes: "If you do not meet the English language requirements to begin the Auckland Foundation Year, you can enrol on our English Language Preparation (ELP) Programme first. The English Language Preparation Programme focuses on four core English language skills: Speaking, Writing, Reading, Listening.",
        category: "Collectibles",
        highestBid: "874.01",
        price: "314",
        isActive: true,
        fileType: "mp4",
        sales: "On Auction"
    },
    {
        id: 15,
        img: "assets/img/nft/img-06.jpg",
        likes: "36.42",
        programName: "Robotic Body Art",
        courseDes: "If you do not meet the English language requirements to begin the Auckland Foundation Year, you can enrol on our English Language Preparation (ELP) Programme first. The English Language Preparation Programme focuses on four core English language skills: Speaking, Writing, Reading, Listening.",
        category: "Photography",
        highestBid: "121.81",
        price: "116",
        isActive: false,
        fileType: "mp3",
        sales: "Has Offers"
    },
];

export default expolreNow;
