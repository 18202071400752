import Cart from '../components/cart';
import Wrapper from '../layout/wrapper';
import React from 'react';

const index = () => {
    document.title="Cart | StudyNZ";
    return (
        <Wrapper>
            <Cart />
        </Wrapper>
    );
};

export default index;