import ServiceThree from "../components/services/service-three";
import Wrapper from "../layout/wrapper";
import React from "react";

const index = () => {
  document.title="Service 3 | StudyNZ";
  return (
    <Wrapper>
      <ServiceThree />
    </Wrapper>
  );
};

export default index;
