import SocialLinks from "../../common/social-links";
import { Link } from "react-router-dom";
import React from "react";

const footer_content = {
  footer_info: [
    {
      id: 1,
      title: "Useful links",
      cls: "footer-col-2",
      links: [
        { name: "Contact us", link: "/contact-us" },
        { name: "Help & About us", link: "/about" },
        { name: "Shipping & Returns", link: "/shop-details" },
        { name: "Refund Policy", link: "/shop-details" },
        { name: "About us", link: "/about" },
        { name: "Services", link: "/service" },
      ],
    },
    {
      id: 2,
      title: "Contact info",
      cls: "footer-col-3",
      links: [
        { name: "1 Wagener Place, Auckland 1025, NZ" },
        { name: "+64 22 0000000" },
        { name: "consult@studynz.nz" },
        { name: "Office Hours: 8AM - 11PM" },
        { name: "Sunday - Wekend Day" },
      ],
    },
  ],
  contact_info: [
    {
      id: 1,
      title: "Quick Links",
      support_info: [
        " 1 Wagener Place, Auckland 1025, NZ",
        "+64 22 0000000",
        "consult@studynz.nz",
      ],
      office_time: "Office Hours: 9AM - 4PM",
      off_day: " Friday - Wekend Day",
    },
  ],
  copy_right_text: (
    <>
      © Copyright © {new Date().getFullYear()}
      <a href="https://www.intellapps.io/" target="_blank" rel="noopener noreferrer"> IntellApps.io</a>.<i> All Rights Reserved Copyright</i>
    </>
  ),
};

const { footer_info, copy_right_text } = footer_content;
const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer-area theme-bg pt-160 pb-60">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div
                  className="footer-widget footer-col-1 mb-50 wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <h4 className="footer-widget__title mb-30">
                    <a href="index">
                      <img src="/assets/img/logo/white-logo.png" alt="logo" />
                    </a>
                  </h4>
                  <p>
                    Exerci tation ullamcorper suscipit lobortis nisl aliquip ex
                    ea commodo claritatem itamconse quat.Exerci tation
                    ullamcorper.
                  </p>
                  <div className="footer-widget__social">
                    <SocialLinks />
                  </div>
                </div>
              </div>
              {
                footer_info.map(item =>  <div key={item.id} className="col-xl-3 col-lg-4 col-md-6">
                <div
                  className={`footer-widget ${item.cls} mb-50 wow fadeInUp`}
                  data-wow-delay=".4s"
                >
                  <h4 className="footer-widget__title mb-20">{item.title}</h4>
                  <div className="footer-widget__links">
                    <ul>

                      {
                        item.links.map((link, i) => <li key={i}>
                        <Link to={`${link?.link ? link.link : "/"}`}>{link.name}</Link>
                      </li> )
                      }
                    
                    </ul>
                  </div>
                </div>
              </div>)
              }
              
              <div className="col-xl-3 col-lg-6 col-md-6">
                <div
                  className="footer-widget footer-col-4 mb-50 wow fadeInUp"
                  data-wow-delay=".8s"
                >
                  <h4 className="footer-widget__title mb-20">
                    Subscribe Newslatter
                  </h4>
                  <p>
                    Exerci tation ullamcorper suscipit lobortis nisl aliquip ex
                    ea commodo{" "}
                  </p>
                  <div className="footer-widget__newsletter p-relative">
                    <form action="#">
                      <input type="email" placeholder="Enter Mail" />
                      <button className="footer-widget__fw-news-btn">
                        <i className="fa-solid fa-paper-plane"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-area-bottom theme-bg">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                <div className="footer-widget__copyright">
                  <span>{copy_right_text}</span>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-12">
                <div className="footer-widget__copyright-info info-direction">
                  <ul className="d-flex align-items-center">
                    <li>
                      <a href="#">Terms and conditions</a>
                    </li>
                    <li>
                      <a href="#">Privacy policy</a>
                    </li>
                    <li>
                      <a href="shop">Pricing</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
