import About from "../components/about/about";
import Wrapper from "../layout/wrapper";
import React from "react";

const index = () => {
  document.title = "About | StudyNZ";
  return (
    <Wrapper>
      <About />
    </Wrapper>
  );
};

export default index;
