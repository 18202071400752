import React from "react";
import HomeThree from "../components/home/home-3/home-three";
import LayoutThree from "../layout/layout-3";
import Wrapper from "../layout/wrapper";

const index = () => {
  document.title="Home Three | StudyNZ";
  return (
    <Wrapper>
      <LayoutThree>
        <HomeThree />
      </LayoutThree>
    </Wrapper>
  );
};

export default index;
