const answer_question_data = [
  {
    id: 1,
    question: "What payment do you except?",
    answer: (
      <>
        Our headquarters worldwide take many forms because of the
        differing requirements of specialists in the various fields of science
        and <br />
        engineering. A physics laboratory might contain a particle accelerator
        or vacuum chamber, while a metallurgy laboratory could have apparatus
        for <br />
        casting or refining metals or for testing their strength.
      </>
    ),
    accordion_id: "headingOne",
    collapsed: "",
    data_bs_target: "#collapseOne",
    aria_expanded: true,
    aria_controls: "collapseOne",
    show: "show"
  },
  {
    id: 2,
    question: "Who performs the testing on laboratory specimens?",
    answer: (
      <>
        Our headquarters worldwide take many forms because of the
        differing requirements of specialists in the various fields of science
        and <br />
        engineering. A physics laboratory might contain a particle accelerator
        or vacuum chamber, while a metallurgy laboratory could have apparatus
        for <br />
        casting or refining metals or for testing their strength.
      </>
    ),
    accordion_id: "headingTwo",
    collapsed: "collapsed",
    data_bs_target: "#collapseTwo",
    aria_expanded: false,
    aria_controls: "collapseTwo",
    show: ""

  },
  {
    id: 3,
    question: "What are laboratory tests?",
    answer: (
      <>
        Our headquarters worldwide take many forms because of the
        differing requirements of specialists in the various fields of science
        and <br />
        engineering. A physics laboratory might contain a particle accelerator
        or vacuum chamber, while a metallurgy laboratory could have apparatus
        for <br />
        casting or refining metals or for testing their strength.
      </>
    ),
    accordion_id: "headingThree",
    collapsed: "collapsed",
    data_bs_target: "#collapseThree",
    aria_expanded: false,
    aria_controls: "collapseThree",
    show: ""

  },
  {
    id: 4,
    question: "Who performs the testing on laboratory specimens?",
    answer: (
      <>
        Our headquarters worldwide take many forms because of the
        differing requirements of specialists in the various fields of science
        and <br />
        engineering. A physics laboratory might contain a particle accelerator
        or vacuum chamber, while a metallurgy laboratory could have apparatus
        for <br />
        casting or refining metals or for testing their strength.
      </>
    ),
    accordion_id: "headingFour",
    collapsed: "collapsed",
    data_bs_target: "#collapseFour",
    aria_expanded: false,
    aria_controls: "collapseFour",
    show: ""

  },
  {
    id: 5,
    question: "How can I be sure that my property is in good hands?",
    answer: (
      <>
        Our headquarters worldwide take many forms because of the
        differing requirements of specialists in the various fields of science
        and <br />
        engineering. A physics laboratory might contain a particle accelerator
        or vacuum chamber, while a metallurgy laboratory could have apparatus
        for <br />
        casting or refining metals or for testing their strength.
      </>
    ),
    accordion_id: "headingFive",
    collapsed: "collapsed",
    data_bs_target: "#collapseFive",
    aria_expanded: false,
    aria_controls: "collapseFive",
    show: ""

  },
  {
    id: 6,
    question: "Do I have tp fast before my laboratory",
    answer: (
      <>
        Our headquarters worldwide take many forms because of the
        differing requirements of specialists in the various fields of science
        and <br />
        engineering. A physics laboratory might contain a particle accelerator
        or vacuum chamber, while a metallurgy laboratory could have apparatus
        for <br />
        casting or refining metals or for testing their strength.
      </>
    ),
    accordion_id: "headingSix",
    collapsed: "collapsed",
    data_bs_target: "#collapseSix",
    aria_expanded: false,
    aria_controls: "collapseSix",
    show: ""

  },
  {
    id: 7,
    question: "How to Create account and login Regularly??",
    answer: (
      <>
        Our headquarters worldwide take many forms because of the
        differing requirements of specialists in the various fields of science
        and <br />
        engineering. A physics laboratory might contain a particle accelerator
        or vacuum chamber, while a metallurgy laboratory could have apparatus
        for <br />
        casting or refining metals or for testing their strength.
      </>
    ),
    accordion_id: "headingSeven",
    collapsed: "collapsed",
    data_bs_target: "#collapseSeven",
    aria_expanded: false,
    aria_controls: "collapseSeven",
    show: ""

  },
  {
    id: 8,
    question: "What forms of payment do you accept?",
    answer: (
      <>
        Our headquarters worldwide take many forms because of the
        differing requirements of specialists in the various fields of science
        and <br />
        engineering. A physics laboratory might contain a particle accelerator
        or vacuum chamber, while a metallurgy laboratory could have apparatus
        for <br />
        casting or refining metals or for testing their strength.
      </>
    ),
    accordion_id: "headingEight",
    collapsed: "collapsed",
    data_bs_target: "#collapseEight",
    aria_expanded: false,
    aria_controls: "collapseEight",
    show: ""

  },
];
export default answer_question_data;
