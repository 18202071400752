import ServiceTwo from "../components/services/service-two";
import Wrapper from "../layout/wrapper";
import React from "react";

const index = () => {
  document.title="Service 2 | StudyNZ";
  return (
    <Wrapper>
      <ServiceTwo />
    </Wrapper>
  );
};

export default index;
