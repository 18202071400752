const feedback = [
  {
    id: 1,
    img: "assets/img/icon/testi-ava-01.jpg",
    name: "Darlene Robertson",
    title: " Secretary of (FlaxStudio)",
    des: (
      <>
        StudyNZ is another theme that is beautiful and professinally constructed
        by the Developers. The price for the template is checp but not qualityh
        of product.what a bargain , This theme works for many types of web sites
        and seems to be durble dows nt break and it.
      </>
    ),
  },
  {
    id: 2,
    img: "assets/img/icon/testi-ava-02.jpg",
    name: "Courtney Henry",
    title: "CEO of (FlaxStudio)",
    des: (
      <>
        StudyNZ is another theme that is beautiful and professinally constructed
        by the Developers. The price for the template is checp but not qualityh
        of product.what a bargain , This theme works for many types of web sites
        and seems to be durble dows nt break and it.
      </>
    ),
  },
  {
    id: 3,
    img: "assets/img/icon/testi-ava-03.jpg",
    name: "Kathryn Murphy",
    title: "Manager of (FlaxStudio)",
    des: (
      <>
        StudyNZ is another theme that is beautiful and professinally constructed
        by the Developers. The price for the template is checp but not qualityh
        of product.what a bargain , This theme works for many types of web sites
        and seems to be durble dows nt break and it.
      </>
    ),
  },
  {
    id: 4,
    img: "assets/img/icon/testi-ava-07.png",
    name: "Darlene Robertson",
    title: "Programmer of (FlaxStudio)",
    des: (
      <>
        StudyNZ is another theme that is beautiful and professinally constructed
        by the Developers. The price for the template is checp but not qualityh
        of product.what a bargain , This theme works for many types of web sites
        and seems to be durble dows nt break and it.
      </>
    ),
  },
];

export default feedback;
