import Research from "../components/research/research";
import Wrapper from "../layout/wrapper";
import React from "react";

const index = () => {
  document.title="Research | StudyNZ";
  return (
    <Wrapper>
      <Research />
    </Wrapper>
  );
};

export default index;
