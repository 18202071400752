import TeamTwo from "../components/team/team-two/team-two";
import Wrapper from "../layout/wrapper";
import React from "react";

const index = () => {
  document.title="Team Two | StudyNZ";
  return (
    <Wrapper>
      <TeamTwo />
    </Wrapper>
  );
};

export default index;
