import TeamOne from "../components/team/team/team-one";
import Wrapper from "../layout/wrapper";
import React from "react";

const index = () => {
  document.title="Team One | StudyNZ";
  return (
    <Wrapper>
      <TeamOne />
    </Wrapper>
  );
};

export default index;
