const ServiceOneData = [
  {
    id: 1,
    color: "",
    icon: "flaticon-hemoglobin-test-meter",
    title: "Hemoglobin Test",
    des: "Nam eget dui vel quam sodales semper quis porttitor tortor. Vivamus quis ex nulla.Nam eget dui vel quam",
  },
  {
    id: 2,
    color: "pink-icon",
    btn_color: "pink-hexa",
    icon: "flaticon-blood-test",
    title: "Blood Testing",
    des: "Nam eget dui vel quam sodales semper quis porttitor tortor. Vivamus quis ex nulla.Nam eget dui vel quam",
  },
  {
    id: 3,
    color: "green-icon",
    btn_color: "green-hexa",
    icon: "flaticon-biochemistry",
    title: "Biochemistry",
    des: "Nam eget dui vel quam sodales semper quis porttitor tortor. Vivamus quis ex nulla.Nam eget dui vel quam",
  },
  {
    id: 4,
    color: "sky-icon",
    btn_color: "sky-hexa",
    icon: "flaticon-dna-1",
    title: "Histopatology",
    des: "Nam eget dui vel quam sodales semper quis porttitor tortor. Vivamus quis ex nulla.Nam eget dui vel quam",
  },
  {
    id: 5,
    color: "",
    btn_color: "",
    icon: "flaticon-bacteria",
    title: "Microbioogy",
    des: "Nam eget dui vel quam sodales semper quis porttitor tortor. Vivamus quis ex nulla.Nam eget dui vel quam",
  },
  {
    id: 6,
    color: "pink-icon",
    btn_color: "pink-hexa",
    icon: "flaticon-dna",
    title: "Genetics",
    des: "Nam eget dui vel quam sodales semper quis porttitor tortor. Vivamus quis ex nulla.Nam eget dui vel quam",
  },
];

export default ServiceOneData;
